<template>
  <div class="OtpModal">
    <Modal
      :name="name"
      :hasModalFooter="false"
      maxWidth="404"
    >
      <div slot="body">
        <div class="guest-page-form">
          <div class="form-container">
            <div class="guest-page-form-header-opt text-center">
              <!-- <img src="/images/blue-google-icon.svg" alt="google-icon" /> -->
              <h2 class="title-otp">Uploading and verifying files...</h2>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    name: { type: String, default: "OtpModal" },
  },
};
</script>

<style lang="scss" scoped>
.OtpModal {
  width: 550px;
  @include mobile {
    :deep(.modal) {
      .modal-dialog {
        width: calc(100vw);
      }
    }
  }
  :deep .modal .modal-dialog .modal-content .modal-body {
    padding: 0 24px 24px;
  }
  .guest-page-form-header-opt {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    .title-otp {
      margin-top: 0;
      margin-bottom: 24px;
      color: $text-main;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;

      @include mobile {
      }
    }
  }
  .input-group {
    margin-bottom: 16px;
    &.final {
      text-align: center;
      .login_box {
        color: $text-main;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
  }
  .btn-submit {
    @include btn-common(contained, md);
  }
}
</style>
