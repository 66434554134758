<template>
    <div class="cover">
        <!-- Company profile -->
        <div class="company-profil">
            <h2>{{ $t("about_us.about_us") }}</h2>
            <p>{{ $t("about_us.about_us_content_1") }}</p>
            <p>{{ $t("about_us.about_us_content_2") }}</p>
            <p>{{ $t("about_us.about_us_content_3") }}</p>
    
            <h2>{{ $t("about_us.our_vision") }}</h2>
            <p>{{ $t("about_us.our_vision_content") }}</p>
    
            <h2>{{ $t("about_us.our_mission") }}</h2>
            <p>{{ $t("about_us.our_mission_content") }}</p>
    
            <h2>{{ $t("about_us.features") }}</h2>
            <p>{{ $t("about_us.features_content") }}</p>
        </div>

        <!-- Trading Rules -->
        <div class="tradingRules">
            <div>
                <h2>{{ $t('trading_rules.title') }}</h2>
                <div style="margin-top: 40px;">
                    <h2>A. {{ $t('trading_rules.subtitle_1') }}</h2>
                    <div 
                        v-for="(definition, index) in $t('trading_rules.definitions')" 
                        :key="index" 
                    >
                        <p class="list-item"><span>{{index + 1}}.</span> {{ definition.term_title }} <span>{{ definition.description }}</span></p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>B. {{ $t('trading_rules.subtitle_2') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.registration_process.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h3>{{ $t('trading_rules.registration_detail.title') }}</h3>
                    <p>{{ $t('trading_rules.registration_detail.registration_detail_nvx') }}</p>
                    <p>{{ $t('trading_rules.registration_detail.registration_detail_nvx_detail') }}</p>
                    <div v-for="(definition, index) in $t('trading_rules.registration_detail.steps')" :key="index">
                        <p v-if="index === 0">
                            <span>{{ index + 1 }}.</span> 
                            {{ definition }} 
                            <a :href="`${APP_URL}/`">{{ `${APP_URL}` }}</a>
                        </p>
                        <p v-else>
                            <span>{{ index + 1 }}.</span> 
                            {{ definition }}
                        </p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h3>{{ $t('trading_rules.google_authenticator.title') }}</h3>
                    <p>{{ $t('trading_rules.google_authenticator.deskripsi_1') }}</p>
                    <p>{{ $t('trading_rules.google_authenticator.deskripsi_2') }}</p>
                    <div v-for="(definition, index) in $t('trading_rules.google_authenticator.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h3>{{ $t('trading_rules.login_web_nvx.title') }}</h3>
                    <p>{{ $t('trading_rules.login_web_nvx.deskripsi_1') }}</p>
                    <div v-for="(definition, index) in $t('trading_rules.login_web_nvx.steps')" :key="index">
                        <p v-if="index === 0">
                            <span>{{ index + 1 }}.</span> 
                            {{ definition }} 
                            <a :href="`${APP_URL}/`">{{ `${APP_URL}` }}</a>
                        </p>
                        <p v-else>
                            <span>{{ index + 1 }}.</span> 
                            {{ definition }}
                        </p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h3>{{ $t('trading_rules.start_kyc.title') }}</h3>
                    <div v-for="(definition, index) in $t('trading_rules.start_kyc.steps')" :key="index">
                        <p v-if="index === 0">
                            {{ definition.split('-')[0] }}
                            <a :href="`${APP_URL}/account`">{{ `${APP_URL}/account` }}</a>
                            {{ definition.split('-')[1] }}
                        </p>
                        <p v-else>
                            {{ definition }}
                        </p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>C. {{ $t('trading_rules.representations_and_warranties.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.representations_and_warranties.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>D. {{ $t('trading_rules.obligations_and_responsibilities.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.obligations_and_responsibilities.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>E. {{ $t('trading_rules.customer_data_update.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.customer_data_update.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>F. {{ $t('trading_rules.procedures_for_transaction_activities.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.procedures_for_transaction_activities.list')" :key="index">
                        <h3>{{ index + 1 }}. {{ definition.title }}</h3>
                        <div style="margin-top: 25px;" v-for="(deskripsi, deskripsiIndex) in definition.deskripsi_1" :key="deskripsiIndex">
                            <h4>{{ deskripsi }}</h4>
                        </div>
                        <div v-for="(step, stepIndex) in definition.steps_1" :key="stepIndex">
                        <p><span>{{ stepIndex + 1 }}.</span> {{ step }}</p>
                        </div>
                        
                        <div style="margin-top: 25px;" v-for="(deskripsi, deskripsiIndex) in definition.deskripsi_2" :key="deskripsiIndex">
                            <h4>{{ deskripsi }}</h4>
                        </div>
                        <div v-for="(step, stepIndex) in definition.steps_2" :key="stepIndex">
                        <p><span>{{ stepIndex + 1 }}.</span> {{ step }}</p>
                        </div>

                        <div style="margin-top: 25px;" v-for="(deskripsi, deskripsiIndex) in definition.deskripsi_3" :key="deskripsiIndex">
                            <h4>{{ deskripsi }}</h4>
                        </div>
                        <div v-for="(step, stepIndex) in definition.steps_3" :key="stepIndex">
                        <p><span>{{ stepIndex + 1 }}.</span> {{ step }}</p>
                        </div>

                        <div style="margin-top: 25px;" v-for="(deskripsi, deskripsiIndex) in definition.deskripsi_4" :key="deskripsiIndex">
                            <h4>{{ deskripsi }}</h4>
                        </div>
                        <div v-for="(step, stepIndex) in definition.steps_4" :key="stepIndex">
                        <p><span>{{ stepIndex + 1 }}.</span> {{ step }}</p>
                        </div>

                        <div style="margin-top: 25px;" v-for="(deskripsi, deskripsiIndex) in definition.deskripsi_5" :key="deskripsiIndex">
                            <h4>{{ deskripsi }}</h4>
                        </div>
                        <div v-for="(step, stepIndex) in definition.steps_5" :key="stepIndex">
                        <p><span>{{ stepIndex + 1 }}.</span> {{ step }}</p>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>G. {{ $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                    <p>{{ $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.deskripsi_1') }}</p>
                    <h2>{{ $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.deposit_title') }}</h2>
                </div>
                <div style="margin-top: 40px;">
                    <table class="table">
                        <tr v-for="(item, index) in $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.table_deposit')" :key="index">
                            <td>{{ item.title }}</td>
                            <td>
                                <ul>
                                    <li v-for="(value, valueIndex) in item.value" :key="valueIndex">{{ value }}</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style="margin-top: 40px;">
                    <h2>{{ $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.withdraw_title') }}</h2>
                </div>
                <div style="margin-top: 40px;">
                    <table class="table">
                        <tr v-for="(item, index) in $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.table_withdraw')" :key="index">
                            <td>{{ item.title }}</td>
                            <td>
                                <ul>
                                    <li v-for="(value, valueIndex) in item.value" :key="valueIndex">{{ value }}</li>
                                </ul>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style="margin-top: 40px;">
                    <p>{{ $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.deskripsi_2') }}</p>
                    <table class="table">
                        <tr>
                            <th v-for="(item, index) in $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.withdraw_detail.title')" :key="index">
                                {{ item }}
                            </th>
                        </tr>
                        <tr>
                            <td v-for="(item, index) in $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.withdraw_detail.value')" :key="index">
                                {{ item }}
                            </td>
                        </tr>
                    </table>
                    <p>{{ $t('trading_rules.transaction_fees_and_fund_withdrawal_limits.deskripsi_3') }}</p>
                </div>
                <div style="margin-top: 40px;">
                    <h2>H. {{ $t('trading_rules.transaction_security.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.transaction_security.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>I. {{ $t('trading_rules.crypto_asset_customer_complaint_handling.title_1') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.crypto_asset_customer_complaint_handling.steps_1')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                    <h3>{{ $t('trading_rules.crypto_asset_customer_complaint_handling.title_2') }}</h3>
                    <p>{{ $t('trading_rules.crypto_asset_customer_complaint_handling.deskripsi') }}</p>
                    <div v-for="(definition, index) in $t('trading_rules.crypto_asset_customer_complaint_handling.steps_2')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>J. {{ $t('trading_rules.crypto_asset_customer_dispute_resolution.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.crypto_asset_customer_dispute_resolution.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>K. {{ $t('trading_rules.force_majeure.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.force_majeure.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>L. {{ $t('trading_rules.apu_ppt.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.apu_ppt.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>M. {{ $t('trading_rules.terms_and_conditions.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.terms_and_conditions.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
                <div style="margin-top: 40px;">
                    <h2>N. {{ $t('trading_rules.scheduled_downtime.title') }}</h2>
                    <div v-for="(definition, index) in $t('trading_rules.scheduled_downtime.steps')" :key="index">
                        <p><span>{{index + 1}}.</span> {{ definition }}</p>
                    </div>
                </div>
            </div>
        </div>
    
        <!-- Terms of Use -->
        <div class="termsOfUse">
            <h2 class="title">{{ $t('terms.title') }}</h2>
            <div class="time-revised">
            <p>{{ $t('terms.last_revised') }}</p>
            </div>
            <div class="content-wrap">
            <div class="term-content">
                <div class="content-ac">
                <p v-html="$t('terms.introduce_1', { APP_URL: APP_URL })"></p>
                <p v-html="$t('terms.introduce_2')"></p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_1.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_1.content_1_1') }}
                </p>
                <p v-html="$t('terms.term_1.content_1_2')"></p>
                <p>
                    {{ $t('terms.term_1.content_1_3') }}<br />
                    {{ $t('terms.term_1.content_1_3_1') }}
                </p>
                <div>
                    <div>
                    {{ $t('terms.term_1.content_1_4') }}
                    </div>
                    <div>
                    {{ $t('terms.term_1.content_1_4_1') }}
                    </div>
                    <div>
                    {{ $t('terms.term_1.content_1_4_2') }}
                    </div>
                    <div>
                    {{ $t('terms.term_1.content_1_4_3') }}
                    </div>
                    <div>
                    {{ $t('terms.term_1.content_1_4_4') }}
                    </div>
                    <div>
                    {{ $t('terms.term_1.content_1_4_5') }}
                    </div>
                    <div>
                    {{ $t('terms.term_1.content_1_4_6') }}
                    </div>
                    <div>
                    {{ $t('terms.term_1.content_1_4_7') }}
                    </div>
                </div>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_2.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_2.content_2_1') }}
                </p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_3.title') }}</p>
                </div>
                <div class="content-ac">
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_3.title_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_3.content_3_1_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_3.content_3_1_2') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_3.title_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_3.content_3_2_1') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_3.title_3') }}
                    </p>
                    <p v-html="$t('terms.term_3.content_3_3_1')"></p>
                    <p v-html="$t('terms.term_3.content_3_3_2')"></p>
                    <p>
                    {{ $t('terms.term_3.content_3_3_3') }}
                    </p>
                    <p>
                    {{ $t('terms.term_3.content_3_3_4') }}
                    </p>
                </div>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_4.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_4.content_4_0') }}
                </p>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_4.title_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_1_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_1_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_1_3') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_4.title_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_0') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_3') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_4') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_5') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_6') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_2_7') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_4.title_3') }}
                    </p>
    
                    <p>
                    {{ $t('terms.term_4.content_4_3_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_3_1_0') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_3_1_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_3_1_1.introduce') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_3_1_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_3_1_2.introduce') }}
                    </p>
    
                    <p>
                    {{ $t('terms.term_4.content_4_3_1_3') }}
                    </p>
                    <p>
                    {{ $t('terms.term_4.content_4_3_1_3.introduce') }}
                    </p>
    
                    <p v-html="$t('terms.term_4.content_4_3_1_4')"></p>
                </div>
                </div>
            </div>
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_5.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_5.content_5_1') }}
                </p>
    
                <p>
                    {{ $t('terms.term_5.content_5_2') }}
                </p>
                <p>
                    {{ $t('terms.term_5.content_5_3') }}
                </p>
                <p>
                    {{ $t('terms.term_5.content_5_4') }}
                </p>
                <p>
                    {{ $t('terms.term_5.content_5_5') }}
                </p>
                <p>
                    {{ $t('terms.term_5.content_5_6') }}
                </p>
                <p>
                    {{ $t('terms.term_5.content_5_7') }}
                </p>
                <p>
                    {{ $t('terms.term_5.content_5_8') }}
                </p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_6.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_6.content_6_1') }}
                </p>
                <p>
                    {{ $t('terms.term_6.content_6_2') }}
                </p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_7.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_7.content_7_0') }}
                </p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_8.title') }}</p>
                </div>
                <div class="content-ac">
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_8.content_8_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_8.content_8_1_1') }}<br />
                    {{ $t('terms.term_8.content_8_1_2') }}<br />
                    {{ $t('terms.term_8.content_8_1_3') }}<br />
                    {{ $t('terms.term_8.content_8_1_4') }}<br />
                    {{ $t('terms.term_8.content_8_1_5') }}<br />
                    {{ $t('terms.term_8.content_8_1_6') }}<br />
                    {{ $t('terms.term_8.content_8_1_7') }}<br />
                    {{ $t('terms.term_8.content_8_1_8') }}<br />
                    {{ $t('terms.term_8.content_8_1_9') }}<br />
                    {{ $t('terms.term_8.content_8_1_10') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_8.content_8_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_8.content_8_2_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_8.content_8_2_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_8.content_8_2_3') }}
                    </p>
                    <p>
                    {{ $t('terms.term_8.content_8_2_4') }}
                    </p>
                    <p>
                    {{ $t('terms.term_8.content_8_2_5') }}
                    </p>
                </div>
                <p>
                    {{ $t('terms.term_8.content_8_3') }}
                </p>
                <p>
                    {{ $t('terms.term_8.content_8_4') }}
                </p>
                <p v-html="$t('terms.term_8.content_8_5')"></p>
                <p>
                    {{ $t('terms.term_8.content_8_6') }}
                </p>
                <p>
                    {{ $t('terms.term_8.content_8_7') }}
                </p>
                <p>
                    {{ $t('terms.term_8.content_8_8') }}
                </p>
                <p>
                    {{ $t('terms.term_8.content_8_9') }}
                </p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_9.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_9.content_9_1') }}
                </p>
                <p>
                    {{ $t('terms.term_9.content_9_2') }}
                </p>
                <p>
                    {{ $t('terms.term_9.content_9_3') }}
                </p>
                <p>
                    {{ $t('terms.term_9.content_9_4') }}
                </p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_10.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_10.content_10_1') }}
                </p>
                <p>
                    {{ $t('terms.term_10.content_10_2') }}
                </p>
                <p>
                    {{ $t('terms.term_10.content_10_3') }}
                </p>
                <p>
                    {{ $t('terms.term_10.content_10_4') }}
                </p>
                <p>
                    {{ $t('terms.term_10.content_10_5') }}
                </p>
                </div>
            </div>
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_11.title') }}</p>
                </div>
                <div class="content-ac">
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_11.content_11_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_11.content_11_1_1') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_11.content_11_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_11.content_11_2_1') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_11.content_11_3') }}
                    </p>
                    <p>
                    {{ $t('terms.term_11.content_11_3_1') }}
                    </p>
                </div>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_11.content_11_4') }}
                    </p>
                    <p>
                    {{ $t('terms.term_11.content_11_4_1') }}
                    </p>
                </div>
                </div>
            </div>
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_12.title') }}</p>
                </div>
                <div class="content-ac">
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_12.content_12_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_3') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_4') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_5') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_6') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_7') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_8') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_9') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_10') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_11') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_12') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_13') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_14') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_15') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_16') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_17') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_18') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_19') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_20') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_21') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_22') }}
                    </p>
                    <p>
                    {{ $t('terms.term_12.content_12_1_23') }}
                    </p>
                </div>
                <p>
                    {{ $t('terms.term_12.content_12_2') }}
                </p>
                <p>
                    {{ $t('terms.term_12.content_12_3') }}
                </p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_13.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_13.content_13_1') }}
                </p>
                <div class="term__sub-content">
                    <p>
                    {{ $t('terms.term_13.content_13_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_13.content_13_2_0_1') }}
                    {{ $t('terms.term_13.content_13_2_0_2') }}<br />
                    </p>
                    <p>
                    {{ $t('terms.term_13.content_13_2_1') }}
                    </p>
                    <p>
                    {{ $t('terms.term_13.content_13_2_2') }}
                    </p>
                    <p>
                    {{ $t('terms.term_13.content_13_2_3') }}
                    </p>
                    <p>
                    {{ $t('terms.term_13.content_13_2_4') }}
                    </p>
                </div>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_14.title') }}</p>
                </div>
                <div class="content-ac">
                <p>
                    {{ $t('terms.term_14.content_14_1') }}
                </p>
                <p>
                    {{ $t('terms.term_14.content_14_2') }}
                </p>
                <p>
                    {{ $t('terms.term_14.content_14_3') }}
                </p>
                <p>
                    {{ $t('terms.term_14.content_14_4') }}
                </p>
                <p>
                    {{ $t('terms.term_14.content_14_5') }}
                </p>
                <p>
                    {{ $t('terms.term_14.content_14_6') }}
                </p>
                <p v-html="$t('terms.term_14.content_14_7')"></p>
                </div>
            </div>
    
            <div class="term-content">
                <div class="title-header">
                <p>{{ $t('terms.term_end_note.title') }}</p>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_URL,
    };
  },
}
</script>

<style lang="scss" scoped>
h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: $text-main;
    margin-bottom: 4px;

    &:first-child {
        margin-top: 0;
    }
}
h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: $text-main;
    margin-bottom: 4px;

    &:first-child {
        margin-top: 0;
    }
}
h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
    color: $text-main;
    margin-bottom: 4px;

    &:first-child {
        margin-top: 0;
    }
}
p {
    color: $text-secondary;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
}
span {
    color: $text-secondary;
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
}
table {
    width: 100%;
}
td, th {
    height: 50px;
    font-size: 20px;
    color: #333;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    border: 2px solid black;
}
.list-item {
    text-decoration: dotted;
    font-weight: bold;
}
.cover {
    width: 100%;
    height: fit-content;
}
.company-profil {
    width: 100%;
    height: fit-content;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid black;
}
.tradingRules {
    width: 100%;
    height: fit-content;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid black;
    margin-top: 20px;
    margin-bottom: 20px;
}
.termsOfUse {
    width: 100%;
    height: fit-content;
    border-radius: 20px;
    padding: 20px;
    border: 2px solid black;
}
</style>
