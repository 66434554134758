<template>
  <div class="container_withdraw_usd">
    <div class="title">{{ $t('menu.withdraw_usd') }}</div>

    <div class="box-content">
      <div class="left_withdraw_fiat">
        <div class="box_left1">
          <div class="list-balance" v-if="!isShowOtp">
            <div class="content-item-balance">
              <p class="item-balance">
                <span>{{ $t('funds.withdrawals.total_balance') }}</span>
                <strong class="text-right"
                  >{{
                    balance.balance
                      | splitAmount(currencyName)
                      | formatCurrencyAmount(currencyName, '0')
                  }}
                  IDR</strong
                >
              </p>
            </div>
            <div class="content-item-balance">
              <p class="item-balance item-locked-balance">
                <span class="tooltip-container"
                  >{{ $t('funds.balances.locked_balance') }}
                  <div class="item group-tooltip">
                    <span class="help-box">
                      <img src="/images/icon/Question.png" alt="" />
                      <div class="box-tooltip">
                        <div class="bubble top locked-balance-text">
                          {{ $t('funds.balances.locked_balance_desc') }}
                        </div>
                      </div>
                    </span>
                  </div>
                </span>
              </p>
              <div class="text-center" v-if="!isShowDetails">
                <button
                  @click="isShowDetails = true"
                  style="background-color: #f8f8f8; margin: 8px 7px 0 0"
                >
                  {{ $t('funds.balances.show_details') }}
                </button>
                <img src="/images/arrow-down-lite.svg" alt="" />
              </div>
              <div v-if="isShowDetails">
                <p class="item-balance" style="margin: 8px 0">
                  <span>{{ $t('funds.balances.in_transaction') }}</span>
                  <strong class="text-right"
                    >{{
                      balance.in_order
                        | splitAmount(currencyName)
                        | formatCurrencyAmount(currencyName, '0')
                    }}
                    IDR</strong
                  >
                </p>
                <p class="item-balance" style="margin-bottom: 8px">
                  <span>{{ $t('funds.balances.pending_withdrawal') }}</span>
                  <strong class="text-right"
                    >{{
                      balance.pending_withdrawal
                        | splitAmount(currencyName)
                        | formatCurrencyAmount(currencyName, '0')
                    }}
                    IDR</strong
                  >
                </p>
                <p class="item-balance">
                  <span>{{ $t('funds.balances.tradable_balance') }}</span>
                  <strong class="text-right"
                    >{{
                      balance.tradable_balance
                        | splitAmount(currencyName)
                        | formatCurrencyAmount(currencyName, '0')
                    }}
                    IDR</strong
                  >
                </p>
                <hr style="border-top: 2.5px solid #ddd; margin: 8px 0" />
                <p class="item-balance">
                  <span>{{ $t('funds.balances.total_locked_balance') }}</span>
                  <strong class="text-right"
                    >{{
                      balance.total_locked_balance
                        | splitAmount(currencyName)
                        | formatCurrencyAmount(currencyName, '0')
                    }}
                    IDR</strong
                  >
                </p>
              </div>
            </div>
            <div class="text-center" v-if="isShowDetails">
              <button
                @click="isShowDetails = false"
                style="background-color: #ffffff; margin-right: 7px"
              >
                {{ $t('funds.balances.hide_details') }}
              </button>
              <img src="/images/arrow-up-lite.svg" alt="" />
            </div>
          </div>
          <div v-else class="list-balance">
            <p class="item-balance">
              <span>{{ $t('withdraw_fiat.bank_name') }}</span>
              <strong class="text-right">
                {{ stringToObj(selectedBankAccounts)?.bank.toUpperCase() }} -
                {{ bankCodeToName(stringToObj(selectedBankAccounts)?.bank) }}
              </strong>
            </p>
            <p class="item-balance">
              <span>{{ $t('withdraw_fiat.account_name') }}</span>
              <strong class="text-right">
                {{ stringToObj(selectedBankAccounts)?.owner_name }}
              </strong>
            </p>
            <p class="item-balance">
              <span>{{ $t('withdraw_fiat.account_number') }}</span>
              <strong class="text-right">
                {{ stringToObj(selectedBankAccounts)?.account_number }}
              </strong>
            </p>
            <p class="item-balance">
              <span>{{ $t('withdraw_fiat.inputted_amount') }}</span>
              <strong class="text-right">
                {{ inputtedAmount | formatCurrencyAmount(currencyName, '0') }}
                IDR
              </strong>
            </p>
            <p class="item-balance">
              <span>{{ $t('withdraw_fiat.fee') }}</span>
              <strong class="text-right">
                {{ withdrawLimit.fee | formatCurrencyAmount(currencyName, '0') }}
                IDR
              </strong>
            </p>
            <p class="item-balance" v-if="isIndoAccount">
              <span>{{ $t('withdraw_fiat.tax') }}</span>
              <strong class="text-right">
                {{ taxAmount | formatCurrencyAmount(currencyName, '0') }}
                IDR
              </strong>
            </p>
            <p class="item-balance">
              <span>{{ $t('withdraw_fiat.you_will_receive') }}</span>
              <strong class="text-right">
                {{
                  youWillget | splitAmount(currencyName) | formatCurrencyAmount(currencyName, '0')
                }}
                IDR
              </strong>
            </p>
          </div>
        </div>
        <div class="description">
          {{ $t('withdraw_fiat.note') }}
        </div>
        <div v-if="!isLoaded" style="position: relative; padding: 20px 0">
          <div class="wrap-loader"><div class="loader"></div></div>
        </div>
        <div v-else>
          <div v-if="isWithdrawEnable">
            <div class="box_left2" v-if="!isShowOtp">
              <p>
                {{ $t('funds.withdrawals.minimum_withdrawal')
                }}{{
                  (Number(withdrawLimit.minium_withdrawal) + Number(withdrawLimit.fee))
                    | formatCurrencyAmount(currencyName, '0')
                }}
                IDR
              </p>
              <div class="deposit-content">
                <div class="list-balance">
                  <div class="content-item-balance" style="background-color: #e7f3fc">
                    <div class="item-balance" style="color: #0a5485">
                      <span style="color: #0a5485">{{
                        $t('funds.withdrawals.available_balance_to_withdraw')
                      }}</span>
                      <strong class="text-right" style="color: #0a5485"
                        >{{
                          balance.available_balance
                            | splitAmount(currencyName)
                            | formatCurrencyAmount(currencyName, '0')
                        }}
                        IDR</strong
                      >
                    </div>
                  </div>
                </div>

                <div class="address-box">
                  <div class="address_for2">
                    <span class="address_for2__title">
                      {{ $t('funds.withdraw_fiat.amount') }}
                    </span>
                    <span class="address_for2" v-if="!isMobile">
                      <span class="address_for2__dark3"
                        >{{ $t('funds.withdrawals.withdrawal_limit_24') }}
                      </span>
                      <span class="address_for2__dark1"
                        >{{ Number(withdrawDaily).toFixed(2) }}/{{
                          Number(withdrawLimit.daily_limit).toFixed(2)
                        }}
                        {{ 'IDR' }}</span
                      >
                    </span>
                  </div>
                  <div class="amount-idr__wrapper">
                    <InputAmount1
                      ref="amount"
                      v-model="params.amount"
                      :coin="'idr'"
                      :minAmount="minAmount"
                      class="input_w_withdraw"
                      :withdrawLimit="withdrawLimit"
                      :balance="balance"
                      :withdrawDaily="withdrawDaily"
                      placeholder=""
                      @focus="resetErrors"
                      @withdraw="withdraw"
                    />
                    <div class="btn-address-box" v-if="false">
                      <template v-if="isSubmitting">
                        <button type="submit" class="btn btn-submit btn-primary">
                          {{ $t('funds.withdrawals.loading') }}
                        </button>
                      </template>
                      <template v-else>
                        <button
                          type="submit"
                          class="btn btn-submit btn-primary"
                          @click="withdraw()"
                        >
                          {{ $t('funds.withdrawals.submit') }}
                        </button>
                      </template>
                    </div>
                  </div>
                  <span class="address_for2" v-if="false">
                    <span class="address_for2__dark3"
                      >{{ $t('funds.withdrawals.withdrawal_limit_24') }}
                    </span>
                    <span class="address_for2__dark1"
                      >{{ Number(withdrawDaily).toFixed(2) }}/{{
                        Number(withdrawLimit.daily_limit).toFixed(2)
                      }}
                      {{ 'IDR' }}</span
                    >
                  </span>
                </div>
                <div class="address-box">
                  <div class="address_for">
                    {{ $t('funds.withdrawals.bank_accounts') }}
                  </div>
                  <div class="amount-idr__wrapper">
                    <div class="bank-accounts__wrapper">
                      <select-box
                        v-model="selectedBankAccounts"
                        :options="bankAccountsOptions"
                        class="bank-accounts__select"
                        :placeholder="balanceMessage"
                        :title-item="'name'"
                        @input="resetError"
                        :class="{
                          error: errors.has('selectedBankAccounts'),
                          disabled: !isBalanceAvailable,
                        }"
                      />
                      <span v-show="errors.has('selectedBankAccounts')" class="invalid-feedback">{{
                        $t(errors.first('selectedBankAccounts'))
                      }}</span>
                    </div>
                    <div class="btn-address-box">
                      <template v-if="isSubmitting">
                        <button type="submit" class="btn btn-submit btn-primary" disabled>
                          {{ $t('funds.withdrawals.loading') }}
                        </button>
                      </template>
                      <template v-else>
                        <button
                          :disabled="!isBalanceAvailable"
                          type="submit"
                          class="btn btn-submit btn-primary"
                          @click="withdraw()"
                        >
                          {{ isBalanceAvailable ? $t('funds.withdrawals.submit') : $t('funds.withdrawals.withdrawal_placeholder_loading') }}
                        </button>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <WithdrawOTP
              v-else
              :duration="60"
              @submit-otp="handleSubmitOtp"
              @resend-otp="handleResendOtp"
              @resend-otp-sms="handleResendOtpSMS"
              :error="errors.first('otp_sms')"
              :resetErrors="resetErrors"
              :phone_no="phone_no"
            />
          </div>
          <div v-else class="alert alert-danger text-center space-top" role="alert">
            {{ $t('funds.disable_coin_msg') }}
          </div>
        </div>

        <div class="withdrawal__note-wrapper">
          <p class="note-wrapper__title">
            {{ $t('withdraw_fiat.notes.please_note') }}
          </p>
          <ul style="padding-left: 0">
            <li class="txt__note-item">
              {{ $t('withdraw_fiat.notes.note_1') }}
            </li>
            <li class="txt__note-item">
              {{ $t('withdraw_fiat.notes.note_2') }}
            </li>
            <li class="txt__note-item">
              {{ $t('withdraw_fiat.notes.note_3') }}
            </li>
            <li class="txt__note-item">
              {{ $t('withdraw_fiat.notes.note_4') }}
            </li>
            <li class="txt__note-item">
              {{ $t('withdraw_fiat.notes.note_5') }}
            </li>
          </ul>
        </div>
        <div class="withdrawal__footer-wrapper">
          <div class="footer__desc">
            {{ $t('chainalysis_note') }}
          </div>
          <div class="footer__logo">
            <img src="/images/withdrawal-footer-logo.svg" alt="" draggable="false" />
          </div>
        </div>
      </div>
      <div class="right_withdraw_usd">
        <div class="box_right1">
          <withdraw-transaction :kyc="kyc" />
        </div>
      </div>
    </div>
    <otp-modal
      ref="otp_modal"
      :submit="createTransaction"
      :params="getOtpParams"
      :is-disable-button="isDisableButton"
      :contact_email="siteSetting.contact_email"
    />
    <withdrawal-modal
      :isShow="isShowWithdrawalModal"
      @close="handleCloseWithdrawalModal"
      @submitWithdrawal="handleSubmitWithdrawal"
      :kyc="kyc"
      :travelRuleAmount="travelRuleUsd.limit_amount"
    />
    <add-bank-modal name="addBankForm" :kyc="kyc" @addBankSuccess="getBankAccounts" />
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import BigNumber from 'bignumber.js';
import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
import InputRequired from '@/components/shared_components/funds/withdraw_usd/InputRequired';
import InputRequiredLocalize from '@/components/shared_components/funds/withdraw_usd/InputRequiredLocalize';
import InputAccountNo from '@/components/shared_components/funds/withdraw_usd/InputAccountNo';
import WithdrawTransaction from '@/components/shared_components/funds/withdraw_usd/WithdrawTransaction.vue';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import InputAmount1 from '@/components/shared_components/funds/common/InputAmount1';
import WithdrawalModal from '@/components/shared_components/funds/common/WithdrawalModal.vue';
import WithdrawOTP from '@/components/shared_components/funds/withdraw_usd/WithdrawOTP.vue';
import SelectBox from '@/components/shared_components/common/SelectBox';
import { isNull, isUndefined, isEmpty } from 'lodash';
import AddBankModal from '@/components/shared_components/common/AddBankModal.vue';
import { mapState } from 'vuex';

export default {
  name: 'WithdrawFiat',
  components: {
    SelectBox,
    WithdrawTransaction,
    InputRequired,
    InputRequiredLocalize,
    InputAccountNo,
    OtpModal,
    WithdrawalModal,
    InputAmount1,
    WithdrawOTP,
    AddBankModal,
  },
  data() {
    return {
      phone_no: '',
      travelRule: {},
      travelRuleUsd: {},
      currencyName: 'idr',
      balance: {},
      isShowWithdrawalModal: false,
      isShowOtp: false,
      bankAccounts: [],
      selectedBank: null,
      selectedBankAccounts: null,
      params: {
        bank_name: 'Bank Central Asia',
        bank_branch: 'Puri Indah',
        account_name: 'Handoyo Margono',
        account_no: '3725788788',
        otp_sms: '',
        document: {},
        file_document: null,
      },
      isSubmitting: false,
      securitySetting: {},
      withdrawLimit: {},
      withdrawDaily: '0',

      modalName: 'OtpModal',

      isLoaded: false,
      isWithdrawEnable: true,
      isDisableButton: false,
      siteSetting: {},
      totalInOrder: 0,
      isMobile: false,
      kyc: {
        status: '',
        bank_status: '',
      },
      limitBankAccount: 10,
      isShowDetails: false,
    };
  },
  mixins: [RemoveErrorsMixin],
  watch: {
    isMobile(newvalue) {
      this.isMobile = newvalue;
    },
    selectedBankAccounts(newValue) {
      console.log(newValue, "NEW VALUE")
      if (newValue === 'add_new_bank') {
        this.selectedBankAccounts = null;
        if (!this.securitySetting.identity_verified) {
          Message.error(
            this.$i18n.t('funds.withdrawals.errors.kyc'),
            {},
            { position: 'top_right' }
          );
          return;
        }
        window.CommonModal.show('addBankForm');
      }
      if (newValue === 'maximum_bank') {
        this.selectedBankAccounts = null;
      }
    },
  },
  methods: {
    handleCloseWithdrawalModal() {
      this.isShowWithdrawalModal = false;
    },
    getUserKyc() {
      rf.getRequest('UserRequest')
        .getUserKyc()
        .then((res) => {
          this.kyc = res.data || {};
        });
    },
    getMobile() {
      if (window.innerWidth <= 760) {
        return (this.isMobile = true);
      } else {
        return (this.isMobile = false);
      }
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    async validateDepositWithdraw() {
      let isWithdrawFiat = true;
      await rf
        .getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          const setting = window._.filter(res.coins_confirmation, (item) => item.coin === 'idr');
          if (!window._.isEmpty(setting)) {
            isWithdrawFiat = setting[0].is_withdraw;
            this.isWithdrawEnable = isWithdrawFiat === 1 ? true : false;
          }
          this.isLoaded = true;
        })
        .catch((e) => {
          this.isLoaded = true;
        });

      if (this.isWithdrawEnable) {
        rf.getRequest('UserRequest')
          .getUserWithdrawalSetting()
          .then((res) => {
            const userWidthdrawSettings = res.data.filter((obj) => obj.coin === 'idr');
            if (userWidthdrawSettings.length == 0) {
              this.isWithdrawEnable = true;
            } else {
              this.isWithdrawEnable = false;
            }
          });
      }
    },
    getUsdBalance() {
      this.balance = {};
      rf.getRequest('UserRequest')
        .getDetailsUsdBalance('idr')
        .then((res) => {
          this.balance = {
            balance: new BigNumber(`${res.data.main.balance || 0}`).add(
              `${res.data.exchange.balance || 0}`
            ),
            available_balance: new BigNumber(`${res.data.main.available_balance || 0}`),
            in_order: new BigNumber(`${res.data.exchange.balance || 0}`).minus(
              `${res.data.exchange.available_balance || 0}`
            ),
            pending_withdrawal: new BigNumber(`${res.data.main.balance || 0}`).minus(
              `${res.data.main.available_balance || 0}`
            ),
            tradable_balance: new BigNumber(`${res.data.exchange.available_balance || 0}`),
            total_locked_balance: new BigNumber(`${res.data.exchange.balance || 0}`)
              .minus(`${res.data.exchange.available_balance || 0}`)
              .add(`${res.data.main.balance || 0}`)
              .minus(`${res.data.main.available_balance || 0}`)
              .add(`${res.data.exchange.available_balance}`),
          };
        });
    },

    calculateBalanceInOrder() {
      return rf
        .getRequest('TransactionRequest')
        .getTotalUsdPendingWithdraw()
        .then((res) => {
          const totalUsdPendingWithdraw = res.data.total || '0';
          const inOrder = new BigNumber(`${this.totalInOrder || 0}`)
            .add(`${totalUsdPendingWithdraw}`)
            .toString();
          this.$set(this.balance, 'in_order', inOrder);
        });
    },

    getWithdrawFiatDaily() {
      rf.getRequest('UserRequest')
        .getWithdrawFiatDaily()
        .then((res) => {
          this.withdrawDaily = `${res.data || 0}`;
        });
    },
    async getTravelRuleLimit() {
      await rf
        .getRequest('UserRequest')
        .getTravelRuleLimit()
        .then((res) => {
          this.travelRule = res?.data.find((item) => item.travel_rule_type === 'withdraw_idr');
          this.travelRuleUsd = res?.data.find((item) => item.travel_rule_type === 'withdraw_crypto');
        });
    },

    onBalanceUpdated(newBalance) {
      this.getUsdBalance();
      this.getWithdrawFiatDaily();
    },

    onOtpUpdated() {
      this.getSecuritySettings();
      this.getSettingWithdrawLimit(false);
    },

    getSocketEventHandlers() {
      return {
        MainBalanceUpdated: this.onBalanceUpdated,
        BalanceUpdated: this.onBalanceUpdated,
        OtpUpdated: this.onOtpUpdated,
      };
    },

    validate() {
      this.resetError();
      if (new BigNumber(`${this.withdrawLimit.daily_limit || 0}`).comparedTo(0) === 0) {
        this.errors.add('amount', this.$i18n.t('funds.withdrawals.errors.not_allowed_withdraw'));
      }
      if (
        isNull(this.selectedBankAccounts) ||
        isUndefined(this.selectedBankAccounts) ||
        isEmpty(this.selectedBankAccounts)
      ) {
        this.errors.add('selectedBankAccounts', this.$i18n.t('notice.errors.required'));
      }
      this.$refs.amount.validate();

      if (this.errors.count() > 0) {
        return false;
      }
      return true;
    },

    async withdraw() {
      this.isSubmitting = true
      await this.getTravelRuleLimit();
      if (!this.validate()) {
        this.isSubmitting = false
        return false;
      }
      if (!this.securitySetting.identity_verified) {
        Message.error(
          this.$i18n.t('messages.user_cannot_withdraw_fiat'),
          {},
          { position: 'top_right' }
        );
        this.isSubmitting = false
        return;
      }
      if (this.securitySetting.identity_verified && !this.securitySetting.otp_verified) {
        Message.error(
          this.$i18n.t('account.otp.should_enable_first'),
          {},
          { position: 'top_right' }
        );
        this.isSubmitting = false
        return;
      }
      if (this.isOpenTravelRuleForm) {
        this.isShowWithdrawalModal = true;
      } else {
        this.handleSubmitWithdrawal();
      }
    },

    async handleSubmitWithdrawal(document = {}, file_document) {
      this.isShowWithdrawalModal = false;
      this.resetErrors();
      this.params.document = { ...document };
      this.params.file_document = file_document;
      let params = {
        type: 'withdraw',
        currency: 'idr',
      };
      await rf
        .getRequest('UserRequest')
        .resendWA(params)
        .then((res) => {
          if (res.success) {
            this.isShowOtp = true;
            this.isSubmitting = false
          }
        });
    },

    async handleResendOtp() {
      this.resetErrors();
      let params = {
        type: 'withdraw',
        currency: 'idr',
      };
      await rf
        .getRequest('UserRequest')
        .resendWA(params)
        .then((res) => {
          if (res.success) {
            Message.success(this.$i18n.t('funds.withdrawals.resend-sms-success_message'));
          }
        });
    },
    async handleResendOtpSMS() {
      console.log("resend otp sms, this feature temporarily turned off")
      this.resetErrors();
      let params = {
        type: 'withdraw',
        currency: 'idr',
      };

      // COMMENT DURING DEVELOPMENT
      await rf
        .getRequest('UserRequest')
        .resendSMS(params)
        .then((res) => {
          if (res.success) {
            Message.success(this.$i18n.t('funds.withdrawals.resend-sms-success_message'));
          }
        });
    },

    async handleSubmitOtp(otp) {
      this.resetErrors();
      this.isDisableButton = true;
      this.isSubmitting = true;
      if (otp.length < 6) {
        this.errors.add({
          field: 'otp_sms',
          msg: this.$t('otp_sms_required'),
        });
        return;
      }
      this.params.otp_sms = otp;
      let params = {
        ...this.getParams(),
      };
      await rf
        .getRequest('TransactionRequest')
        .withdrawUsd(params)
        .then((res) => {
          if (this.securitySetting.otp_verified) {
            CommonModal.show(this.modalName);
          } else {
            this.createTransaction(this.getParams());
          }
        })
        .catch((error) => {
          if (error.response.data.errors) {
            const errors = error.response.data.errors;
            if (!errors['otp_sms']) {
              if (this.securitySetting.otp_verified) {
                CommonModal.show(this.modalName);
              } else {
                this.createTransaction(this.getParams());
              }
            } else {
              this.errors.add({
                field: 'otp_sms',
                msg: this.$t(errors['otp_sms'][0]),
              });
            }
          }
        });
    },
    stringToObj(str) {
      return JSON.parse(str);
    },

    getParams() {
      const selectedBank = JSON.parse(this.selectedBankAccounts);
      return {
        amount: `-${this.params.amount || '0'}`,
        bank_name: this.params.bank_name,
        bank_branch: this.params.bank_branch,
        account_name: selectedBank?.owner_name,
        account_no: selectedBank?.account_numberNotEncrypt,
        bank: selectedBank?.bank,
        otp_sms: this.params.otp_sms,
      };
      return res;
    },

    addFormData(form, key, value) {
      if (value) {
        form.append(key, value);
      }
      return form;
    },

    getFormDataParams() {
      const selectedBank = JSON.parse(this.selectedBankAccounts);
      let fd = new FormData();
      fd = this.addFormData(fd, 'amount', `-${this.params.amount || '0'}`);
      fd = this.addFormData(fd, 'bank_name', this.params.bank_name);
      fd = this.addFormData(fd, 'bank_branch', this.params.bank_branch);
      fd = this.addFormData(fd, 'account_name', selectedBank?.owner_name);
      fd = this.addFormData(fd, 'account_no', selectedBank?.account_numberNotEncrypt);
      fd = this.addFormData(fd, 'bank', selectedBank?.bank);
      fd = this.addFormData(fd, 'otp_sms', this.params.otp_sms);
      fd = this.addFormData(fd, 'document', JSON.stringify(this.params?.document));
      fd = this.addFormData(fd, 'file_document', this.params.file_document);
      return fd;
    },
    resetForm() {
      this.params = {
        amount: '',
        bank_name: '',
        bank_branch: '',
        account_name: '',
        account_no: '',
        otp_sms: '',
        document: {},
        file_document: null,
      };
      this.selectedBankAccounts = null;
      this.isShowOtp = false;
      this.isDisableButton = false;
      this.isSubmitting = false;
      this.errors.clear();
    },

    createTransaction(params) {
      this.isDisableButton = true;
      this.isSubmitting = true;
      let fd = this.getFormDataParams();
      fd = this.addFormData(fd, 'otp', params?.otp);
      if (!this.securitySetting.otp_verified || !this.securitySetting.identity_verified) {
        this.isDisableButton = false;
        this.isSubmitting = false;
        CommonModal.hide(this.modalName);
        Message.error(this.$i18n.t('funds.withdrawals.errors.kyc'), {}, { position: 'top_right' });
        return;
      }
      rf.getRequest('TransactionRequest')
        .withdrawUsd(this.isOpenTravelRuleForm ? fd : params)
        .then((res) => {
          this.onCreateTransaction(res.data);
          this.$broadcast('TransactionCreated');
          this.isDisableButton = false;
          CommonModal.hide(this.modalName);
          this.getUsdBalance();
          this.isShowOtp = false;
          this.resetForm();
        })
        .catch((error) => {
          this.isDisableButton = false;
          this.isSubmitting = false;
          if (error.response.data.errors) {
            const errors = error.response.data.errors;
            Object.keys(errors).forEach((key) => {
              this.errors.add({
                field: key,
                msg: this.$t(errors[key][0]),
              });
            });
            if (!this.errors.has('otp')) {
              CommonModal.hide(this.modalName);
            }
          } else {
            CommonModal.hide(this.modalName);
            Message.error(this.$t(error.response.data.message), {}, { position: 'top_right' });
          }
        });
    },

    onCreateTransaction(data) {
      this.isSubmitting = false;
      this.params = {};
      this.getWithdrawFiatDaily();
      CommonModal.hide(this.modalName);
      Message.success(
        this.$i18n.t('funds.withdrawals.success_message'),
        {},
        { position: 'top_right' }
      );
    },

    getSecuritySettings() {
      rf.getRequest('UserRequest')
        .getSecuritySettings()
        .then((res) => {
          this.securitySetting = res.data;
        });
    },

    init() {
      this.getUsdBalance();
      this.getWithdrawFiatDaily();
      this.getSettingWithdrawLimit();
      this.getMasterData();
      this.getBankAccounts();
    },
    bankCodeToName(code) {
      switch (code) {
        case 'bca':
          return 'PT. BANK CENTRAL ASIA TBK';
        case 'mandiri':
          return 'PT. BANK MANDIRI (PERSERO) TBK';
        case 'permata':
          return 'PT. BANK PERMATA TBK';
        case 'cimb':
          return 'PT. BANK CIMB NIAGA TBK';
        case 'bni':
          return 'PT. BANK NEGARA INDONESIA (PERSERO)';
        case 'bri':
          return 'PT. BANK RAKYAT INDONESIA (PERSERO)';
        default:
          return '';
      }
    },

    // maskAccountNumber(accountNumber) {
    //   if (!accountNumber) return '';
    //   const length = accountNumber.length;
    //   if (length <= 4) return accountNumber;
    //   return '*'.repeat(length - 4) + accountNumber.slice(-4);
    // },

    getBankAccounts() {
      return rf
        .getRequest('UserRequest')
        .getBankAccount()
        .then((res) => {
          this.bankAccounts = res?.data;
        });
    },

    getSettingWithdrawLimit(useCache = true) {
      rf.getRequest('UserRequest')
        .getCurrentUser(useCache)
        .then((res) => {
          this.phone_no = res.data.phone_no;
          let securityLevel = res.data.security_level;
          let withdrawLimit = {};
          rf.getRequest('MasterdataRequest')
            .getAll()
            .then((res) => {
              withdrawLimit =
                window._.find(res.withdrawal_limits, (wl) => {
                  return wl.security_level === securityLevel && wl.currency === this.currencyName;
                }) || {};
              this.withdrawLimit = withdrawLimit;
            });
        });
    },
    getMasterData() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((data) => {
          const masterdata = data;
          window._.each(masterdata.settings, (item) => {
            this.siteSetting[item.key] = item.value;
          });
        });
    },
  },
  computed: {
    isBalanceAvailable() {
      return this.balance.balance || this.balance.in_order || this.balance.available_balance;
    },
    balanceMessage() {
      return this.isBalanceAvailable
        ? this.$t('funds.withdrawals.choose_bank_placeholder')
        : 'Loading...';
    },
    ...mapState({
      taxSettings: (state) =>
        state.taxSettings.filter((item) => item.type_exchange.includes('withdrawal')),
    }),
    getOtpParams() {
      if (!this.isOpenTravelRuleForm) return this.getParams();
      return this.isOpenTravelRuleForm ? this.getFormDataParams() : this.getParams();
    },
    isOpenTravelRuleForm() {
      if (!this.params.amount) return;
      if (this.travelRule?.limit_amount == 0) return false;
      return new BigNumber(this.params?.amount || 0)
        .plus(this.withdrawLimit.fee || 0)
        .gte(this.travelRule?.limit_amount || 0);
    },
    minAmount() {
      let rs = new BigNumber(this.withdrawLimit?.fee || 0).plus(
        this.withdrawLimit.minium_withdrawal || 0
      );
      if (this.isIndoAccount) {
        rs = rs.div(1 - this.taxRate);
      }
      return rs.toString();
    },
    bankAccountsOptions() {
      let bankOptions = this.bankAccounts.map((item) => {
        return {
          id: JSON.stringify(item),
          name: `${this.bankCodeToName(item?.bank)} - ${item?.account_number}`,
        };
      });
      if (bankOptions.length < this.limitBankAccount) {
        bankOptions.push({
          id: 'add_new_bank',
          name: this.$t('bank_account_management.add_new_bank'),
        });
      }
      if (bankOptions.length == this.limitBankAccount) {
        bankOptions.push({
          id: 'maximum_bank',
          name: this.$t('bank_account_management.maximum_bank_note', {
            limit: this.limitBankAccount,
          }),
        });
      }

      return bankOptions;
    },
    inputtedAmount() {
      return new BigNumber(this.params?.amount || 0).plus(this.withdrawLimit.fee || 0).toString();
    },
    isIndoAccount() {
      return this.kyc?.country === 'Indonesia' && this.kyc.status === 'verified';
    },
    youWillget() {
      return new BigNumber(this.inputtedAmount)
        .sub(this.withdrawLimit.fee)
        .sub(this.taxAmount)
        .toString();
    },
    taxRate() {
      return this.taxSettings.reduce((acc, item) => {
        return new BigNumber(acc).plus(item.tax_rate).toString();
      }, 0);
    },
    taxAmount() {
      if (!this.isIndoAccount) {
        return 0;
      }
      return new BigNumber(this.inputtedAmount).mul(this.taxRate).toString();
    },
  },
  created() {
    document.title = this.$t('menu.withdraw_usd') + ` – ${this.tabTitle}`;
    this.init();
    this.validateDepositWithdraw();
    this.getUserKyc();
    this.getSecuritySettings();
    this.getMobile();
    window.addEventListener('resize', this.getMobile);
  },
  destroyed() {
    window.removeEventListener('resize', this.getMobile);
  },
};
</script>

<style lang="scss">
.list-balance {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;

  .content-item-balance {
    background-color: #f8f8f8;
    padding: 16px;
    border-radius: 7px;
  }

  .item-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $text-secondary;

    @include mobile {
      font-size: 14px;
    }
  }
}
</style>
<style lang="scss">
.bank-accounts__wrapper {
  flex: 1;
  display: flex;
  justify-content: space-between;
  // gap: 15px;
  flex-wrap: wrap;
  flex-direction: column;

  .bank-accounts__select {
    position: relative;
    float: left;
    height: 100%;
    height: 42px;
    color: $text-main2;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #ddd;

    &.error {
      border: 1px solid $color-red;
      background: rgba(255, 91, 91, 0.1);
    }

    .group_search_select {
      height: 100% !important;

      .button_drop_search {
        height: 100%;
        line-height: unset;
        background: transparent;

        span {
          line-height: 24px;
          font-size: 16px;
          font-weight: 300;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: normal;

          @include mobile {
            font-size: 14px;
            line-height: 22px;
            text-transform: capitalize;
          }
        }

        img {
          height: 24px;
          width: 24px;

          @include mobile {
            width: 20px;
            height: 20px;
          }
        }

        .icon-arrow1 {
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      .box_list_search_select {
        margin-top: 4px;
        width: 100%;

        @include mobile {
          width: calc(100vw - 60px);
        }

        .full_name_coin_select {
          font-size: 18px !important;
          font-style: normal;
          font-weight: 300;
          line-height: 32px;

          @include mobile {
            font-size: 16px !important;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.amount-idr__wrapper {
  display: flex;
  justify-content: start;
  gap: 15px;

  :deep(.amount-box) {
    .form-input-amount,
    > * {
      font-family: $helvetica;
    }
  }

  @include mobile {
    gap: 8px;

    :deep(.amount-box) {
      .amount-input {
        input {
          height: 40px;
        }

        .max-amount {
          font-size: 14px;
        }
      }
    }
  }

  .amount-box.input_w_withdraw {
    flex: 1;
  }
}

.withdrawal__footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $text-main-blur;

  @include mobile {
    font-weight: 300;
  }

  .footer__logo {
    display: flex;
    min-width: 130px;
    height: 71px;

    img {
      // width: 100%;
      height: auto;
    }
  }
}

.withdrawal__note-wrapper {
  p {
    margin: unset;
  }

  .note-wrapper__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: $text-main;
    margin-bottom: 8px;
  }

  ul {
    padding-left: 10px;
  }

  .txt__note-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $text-secondary;

    @include mobile {
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
    }

    &::before {
      content: '•';
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 20px;
    }
  }
}

.container_withdraw_usd {
  width: 100%;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1370px;
  margin: 0 auto;
  padding: 25px 15px;

  @include mobile {
    padding: 16px 15px;
    gap: 16px;
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: $text-main;

    @include mobile {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .box-content {
    display: grid;
    grid-template-columns: 45% calc(55% - 24px);
    gap: 24px;

    @include tablet {
      grid-template-columns: 1fr;
    }

    @include mobile {
      grid-template-columns: 1fr;
    }
  }

  .left_withdraw_fiat {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
    padding: 24px 30px;
    background-color: $color-white;
    border-radius: 10px;

    @media (max-width: 1024px) {
      max-width: 500px;
    }

    @include tablet {
      max-width: 100%;
    }

    @include mobile {
      padding: 14px 15px;
      gap: 18px;
    }

    .box_left1 {
      .list-balance {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @include mobile {
          gap: 6px;
        }

        .content-item-balance {
          background-color: #f8f8f8;
          padding: 16px;
          border-radius: 7px;
        }

        .item-balance {
          margin: 0;

          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $text-secondary;

            @include mobile {
              font-size: 14px;
              line-height: 22px;
              font-weight: 300;
            }
          }

          strong {
            float: right;
            font-family: $helvetica;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: $text-main;

            @include mobile {
              font-size: 14px;
            }
          }
        }
      }
    }

    .description {
      display: none;
      font-family: $helvetica;
      font-size: 16px;
      line-height: 20px;
      color: $primary-2;
    }

    .box_left2 {
      display: block;
      width: 100%;
      border-radius: 7px;
      background-color: #f2f3f5;
      padding: 22px;

      > p {
        display: none;
      }

      padding: unset;
      background-color: transparent;

      @include mobile {
        padding: 0px;
      }

      p {
        margin-bottom: 20px;
        @include text-font($helvetica, $secondary-red, $font-root, 500, 22px);

        @include mobile {
          margin-bottom: 17px;
          font-size: $font-smaller;
        }
      }

      .deposit-content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @include mobile {
          gap: 15px;
        }

        .address-box {
          margin-bottom: 0;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .address_for {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $text-secondary;

            @include mobile {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }

          .fee {
            font-family: Roboto;
            font-size: 13px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $color-grey-dusty;
          }

          .group_input_withdraw {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            div {
              width: 50%;
            }

            @include mobile {
              flex-direction: column;
              gap: 10px;

              div {
                width: 100%;
              }
            }
          }
        }

        .address_for2 {
          display: flex;
          justify-content: space-between;
          gap: 5px;

          .address_for2__title {
            text-transform: capitalize;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $text-secondary;

            @include mobile {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            + .address_for2 {
              display: none;
            }
          }

          .address_for2__dark3 {
            @include text-font($helvetica, $dark-3, $font-medium-bigger, 500, 20px);
          }

          .address_for2__dark1 {
            @include text-font($helvetica, $dark-1, $font-medium-bigger, 500, 20px);
          }

          .address_for2__dark3,
          .address_for2__dark1 {
            @include mobile {
              font-size: 10px;
              line-height: 13px;
            }
          }

          .txt-bold {
            color: $primary-0;
            font-weight: 700;
          }

          @include mobile {
            font-size: 14px;
            line-height: 18px;
            justify-content: flex-start;
          }
        }

        .btn-address-box {
          display: flex;
          justify-content: flex-end;
        }

        .btn-submit {
          padding: 9px 32px;
          max-height: 42px;
          color: white;
          background: $bg-main6;
          border-radius: 60px;
          border: none;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;

          @include mobile {
            width: 100%;
            padding: 7px 16px;
            font-size: 14px;
          }
        }

        :deep() {
          .amount-box {
            .amount-input {
              width: 100%;
              background: white;

              .form-input-amount {
                border-radius: 8px;
                border: 1px solid #ddd;
              }

              .max-amount {
                right: 16px;
              }
            }

            .submit {
              display: none;
              cursor: pointer;
              padding: 10px 20px;
              font-family: $helvetica;
              font-size: 16px;
              line-height: 20px;
              background: $primary-1;
              color: white;
              border-radius: 30px;
            }

            .set-max {
              display: none;
              width: 95px;
              justify-content: center;
              color: $primary-0;
              border: 1px solid $primary-0;
            }

            .available.responsive {
              display: none;
            }
          }
        }
      }
    }
  }

  .right_withdraw_usd {
    overflow: hidden;

    .box_right1,
    .table_witdhdrar_usd {
      height: 100%;
      background: white;
      border-radius: 10px;
      overflow: auto;
    }
  }
}

.tooltip-container {
  display: flex;
  column-gap: 5px;
}

.group-tooltip {
  color: $color-red;

  display: flex;
  align-items: center;
  gap: 5px;

  .help-box {
    position: relative;
    img {
      cursor: pointer;
    }
    + span {
      white-space: nowrap;
    }
  }
  .box-tooltip {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 100%;
      translate: -50%;
      border: 5px solid transparent;
      border-bottom-color: #010304;
    }
    width: 230px;
    position: absolute;
    display: none;
    z-index: 999999;
    text-align: center;
    background: #010304;
    left: 50%;
    top: calc(100% + 0.75rem);
    translate: -50%;
    padding: 8px 10px;
    border-radius: 4px;

    .locked-balance-text {
      color: #fff;
      font-weight: 400;
      text-align: left;
      letter-spacing: 0.2px;
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  &:hover .box-tooltip {
    display: block;
  }
}

.item-locked-balance {
  display: inline-flex;
  justify-content: space-between;
  column-gap: 10px;
}

@include mobile {
  .group-tooltip {
    .box-tooltip {
      left: 0;
      top: calc(100% + 0.75rem);
      translate: none;

      &::before {
        left: 0.25rem;
        translate: none;
      }
    }
  }
}

.wrap-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .loader {
    display: block;
    margin: auto;
    border: 5px solid $color-grey-concrete;
    border-radius: 50%;
    border-top: 5px solid $bg-main1;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}
</style>
<style>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
