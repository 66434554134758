<template>
  <deposit-page ref="deposit_page" :configs="configs">
    <template slot="title">{{ $t('funds.deposit.title_page') }}</template>
    <template slot="actions">
      <div class="deposit-content">
        <template v-if="coinName === 'xrp' || coinName === 'eos'">
          <div class="address-box">
            <label for="blockchain_sub_address">{{
              $t('funds.deposit.deposit_tag', { coin: coinName.toUpperCase() })
            }}</label>
            <input
              id="blockchain_sub_address"
              class="form-control address"
              :value="address.blockchain_sub_address"
              readonly
            />
            <div class="btn-box">
              <button
                tabindex="0"
                :class="{ chrome_only: isChrome }"
                type="button"
                class="btn box_left"
                :disabled="!address.blockchain_sub_address"
                v-clipboard:copy="address.blockchain_sub_address"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                <img class="icon_btn" src="/images/wallet/copy-icon.svg" />{{
                  $t('funds.deposit.copy_tag')
                }}
              </button>
              <button
                tabindex="0"
                :class="{ chrome_only: isChrome }"
                type="button"
                class="btn box_right"
                :disabled="!address.blockchain_sub_address"
                @click="showQRCode('blockchain_sub_address')"
              >
                <img class="icon_btn" src="/images/wallet/qr-code-icon.svg" />{{
                  $t('funds.deposit.show_qr')
                }}
              </button>
            </div>
          </div>
        </template>
        <div class="address-box">
          <div
            v-if="coinName"
            class="form-important"
            v-html="
              $t('funds.deposit.important.text2', {
                name: coinName.toUpperCase(),
              })
            "
          ></div>
          <div
            v-if="selectedCoin.coinName == 'usdt' && user.is_otc == 'true'"
            style="display: flex; column-gap: 10px"
          >
            <label class="ng-binding"
              ><input type="radio" value="erc20" v-model="network" name="networkAddress" />
              ERC20</label
            >
            <label class="ng-binding"
              ><input type="radio" value="trc20" v-model="network" name="networkAddress" />
              TRC20</label
            >
          </div>

          <label v-if="coinName" for="address" class="form-address">
            {{$t('funds.deposit.deposit_address', {coin: coinName && coinName.toUpperCase()})}}
            <span v-if="selectedCoin.coinName === 'usdt'">&nbsp;(Ethereum Mainnet ERC20)</span>
          </label>
          <div class="w-100 is-loading">
            <input
              id="address"
              ref="address"
              class="form-control address"
              :value="getNetworkAddress()"
              readonly
            />
            <div v-if="isAddressLoading" class="loader-deposit"></div>
            <div v-if="isAddressLoading" class="fetching-address">
              {{ $t('common.fetching_deposit_address') }}
            </div>
            <!-- <div v-if="isAddressLoading" class="creating-address"></div> -->
          </div>
          <div class="btn-box">
            <button
              tabindex="0"
              :class="{ chrome_only: isChrome }"
              type="button"
              class="btn box_left"
              :disabled="!address.blockchain_address && !address.blockchain_address_trc20"
              v-clipboard:copy="getNetworkAddress()"
              v-clipboard:success="onCopySuccess"
              v-clipboard:error="onCopyError"
            >
              <img class="icon_btn" src="/images/wallet/copy-icon.svg" />{{
                $t('funds.deposit.coppy_address')
              }}
            </button>
            <button
              tabindex="0"
              :class="{ chrome_only: isChrome }"
              type="button"
              class="btn box_right"
              :disabled="!address.blockchain_address && !address.blockchain_address_trc20"
              @click="showQRCode('blockchain_address')"
            >
              <img class="icon_btn" src="/images/wallet/qr-code-icon.svg" />{{
                $t('funds.deposit.show_qr')
              }}
            </button>
          </div>
        </div>
      </div>
      <div class="form-notice">
        <p class="txt-bold">{{ $t('funds.deposit.tips.text1') }}</p>
        <ul>
          <!-- <li
            v-if="
              coinName &&
              coinName.toUpperCase() != 'BTC' &&
              getConfirmation(coinName) === 1
            "
            v-html="
              `${$t('funds.deposit.tips.text2', {
                name: coinName.toUpperCase(),
                count: getConfirmation(coinName),
              })}`
            "
          ></li>
          <li
            v-if="
              coinName &&
              coinName.toUpperCase() != 'BTC' &&
              getConfirmation(coinName) !== 1
            "
            v-html="
              `${$t('funds.deposit.tips.text4', {
                name: coinName.toUpperCase(),
                count: getConfirmation(coinName),
              })}`
            "
          ></li> -->

          <!-- take out "Network: Ethereum Testnet"  -->
          <li
            v-if="selectedCoin.coinName !== 'usdt'"
            v-html="$t('funds.deposit.tips.text5', { coinNetwork: coinNetwork })"
          ></li>
          <li v-html="$t('funds.deposit.tips.text3')"></li>
        </ul>
        <div class="provide">{{ $t('chainalysis_note') }}</div>
        <div class="deposit__footer-wrapper">
          <div class="footer__logo">
            <img src="/images/withdrawal-footer-logo.svg" alt="" />
          </div>
        </div>
      </div>
      <div v-if="coinName">
        <modal name="blockchain_address" width="348">
          <div slot="body" class="qrcode-modal">
            <img
              src="@/assets/images/close-circle.svg"
              alt=""
              class="close-icon"
              v-if="step !== 3"
              @click="hideQRCode('blockchain_address')"
            />
            <div class="qrcode-title">
              {{
                $t('funds.deposit.deposit_address', {
                  coin: coinName.toUpperCase(),
                })
              }}
            </div>
            <div class="text-center">
              <img :src="STORAGE_URL + getQrCode()" class="qr-image" />
            </div>
            <div class="form-group">
              <div class="text-center model-address">
                {{ getNetworkAddress() }}
              </div>
            </div>
            <!-- <div class="form-group"> -->
            <div class="text-center">
              <button
                type="button"
                class="btn-model"
                v-clipboard:copy="getNetworkAddress()"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
              >
                <img
                  class="icon_btn"
                  src="/images/wallet/copy-icon-white.svg"
                  alt="copy-icon-white.svg"
                />
                {{ $t('funds.deposit.coppy_address') }}
              </button>
            </div>
            <!-- </div> -->
          </div>
        </modal>
        <modal name="blockchain_sub_address" width="348">
          <div slot="body" class="qrcode-modal">
            <img
              src="@/assets/images/close-circle.svg"
              alt=""
              class="close-icon"
              v-if="step !== 3"
              @click="hideQRCode('blockchain_sub_address')"
            />
            <div class="text-center qrcode-title">
              {{
                $t('funds.deposit.deposit_tag', {
                  coin: coinName.toUpperCase(),
                })
              }}
            </div>
            <div class="text-center">
              <img :src="STORAGE_URL + address.qr_tag" class="qr-image" />
            </div>
            <div class="form-group">
              <div class="text-center model-address">
                {{ address.blockchain_sub_address }}
              </div>
            </div>
            <div>
              <div class="text-center">
                <button
                  type="button"
                  class="btn-model"
                  v-clipboard:copy="address.blockchain_sub_address"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                >
                  <img
                    class="icon_btn"
                    src="/images/wallet/copy-icon-white.svg"
                    alt="copy-icon-white.svg"
                  />
                  {{ $t('funds.deposit.copy_tag') }}
                </button>
              </div>
            </div>
          </div>
        </modal>
      </div>
    </template>
  </deposit-page>
</template>

<script>
import rf from '@/request/RequestFactory';
import Modal from '@/components/shared_components/common/Modal';
import BaseDepositWithdraw from './BaseDepositWithdraw';
// import BigNumber from 'bignumber.js';
import { mapState } from 'vuex';

export default {
  components: {
    Modal,
    DepositPage: BaseDepositWithdraw,
  },
  data() {
    return {
      step: 1,
      configs: {
        type: 'deposit',
        is_show_title: true,
        msgEmptyData: this.$i18n.t('funds.deposit.no_data'),
      },
      selectedCoin: {},
      address: {},
      currency: {},
      confimations: {},
      isChrome: window.navigator.userAgent.toLowerCase().indexOf('chrome') > 0,
      isAddressLoading: true,
      network: 'erc20',
    };
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
    coinName() {
      if (_.isEmpty(this.selectedCoin.coinName)) {
        return '';
      }
      return this.selectedCoin.coinName;
    },
    coinNetwork() {
      if (_.isEmpty(this.selectedCoin.coinNetwork)) {
        return '';
      }
      return this.selectedCoin.coinNetwork;
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    getQRCodeGoogleUrl() {
      return rf
        .getRequest('UserRequest')
        .getQRCodeGoogleUrl()
        .then((res) => {
          this.qrCodeUrl = res.data.url;
        });
    },

    showQRCode(name) {
      CommonModal.show(name, {
        position: 'center',
        mask: true,
      });
      this.openPageOverflow();
    },
    hideQRCode(name) {
      CommonModal.hide(name);
      this.removePageOverflow();
    },

    onCopySuccess() {
      Message.success(this.$i18n.t('funds.deposit.copy_success'), {}, { position: 'top_right' });
    },

    onCopyError() {
      Message.error(this.$i18n.t('funds.deposit.copy_error'), {}, { position: 'top_right' });
    },

    getDepositAddress() {
      this.isAddressLoading = true;
      let params = {
        currency: this.coinName,
      };
      rf.getRequest('UserRequest')
        .getDepositAddress(params)
        .then((res) => {
          this.address = res.data;
          this.isAddressLoading = false;
        })
        .catch((e) => {
          if (e.response.status == 404) {
            setTimeout(() => {
              this.getDepositAddress();
            }, 5000);
          }
        });
    },

    getConfirmation(coin) {
      const confimation = this.confimations[coin] ? this.confimations[coin].confirmation : 1;
      return parseInt(confimation, 10) || 1;
    },

    getConfirmations() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          this.confimations = window._.keyBy(res.coins_confirmation, 'coin');
        });
    },

    getNetworkAddress() {
      return this.network === 'erc20'
        ? this.address.blockchain_address
        : this.address.blockchain_address_trc20;
    },
    getQrCode() {
      return this.network === 'erc20' ? this.address.qrcode : this.address.qrcode_trc20;
    },
  },
  mounted() {
    this.$refs.deposit_page.$on('CHANGE_SELECTED_COIN', (selectedCoin) => {
      this.selectedCoin = selectedCoin;
      this.address = {};
      this.getDepositAddress();
      this.getConfirmations();
    });

    document.title = this.$t('menu.deposits') + ` – ${this.tabTitle}`;

    this.$on('UPDATED_LOCALE', () => {
      this.configs.msgEmptyData = this.$i18n.t('funds.deposit.no_data');
    });
  },
};
</script>
<style lang="scss" scoped>
:deep() {
  .is-loading {
    position: relative;
  }

  .loader-deposit {
    // display: block;
    top: 6px;
    left: 3px;
    position: absolute;
    margin: auto;
    border: 5px solid $color-grey-concrete;
    border-radius: 50%;
    border-top: 5px solid $bg-main1;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
  }

  .fetching-address,
  .creating-address {
    position: absolute;
    top: 12px;
    left: 45px;
  }

  #blockchain_address {
    .modal-dialog {
      max-width: calc(100vw - 32px);
    }
  }

  .modal {
    .modal-dialog {
      @include mobile {
        padding: 0;
      }

      .modal-content {
        .modal-header,
        .modal-foot {
          display: none;
        }
      }
    }
  }
}

.clearfix-20 {
  clear: both;
  width: 100%;
  height: 20px;
  display: block;
}

.form-group {
  margin-bottom: 24px;
}

.model-address {
  color: $text-main;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  word-break: break-all;
}

.btn-model {
  @include btn-common(contained, md, false);
}

.txt-bold {
  color: $text-main;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  @include mobile {
    font-size: 14px;
  }
}

.form-control,
.btn {
  border-radius: 0;
}

.text-center {
  text-align: center;
}

.qrcode-modal {
  position: relative;

  .close-icon {
    position: absolute;
    top: -12px;
    right: -12px;
    cursor: pointer;
  }

  .qr-image {
    max-width: 145px;
    height: auto;
  }
}

.qrcode-title {
  color: $text-main;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.provide {
  color: $text-main-blur;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
}

.deposit__footer-wrapper {
  display: flex;
  gap: 5px;
  margin-top: 16px;
  width: 100%;

  .footer__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    display: flex;
  }

  .footer__logo {
    display: flex;
    min-width: 130px;
    height: 71px;

    img {
      // width: 100%;
      height: auto;
    }
  }
}

.form-notice {
  height: auto;
  padding-top: 16px;

  li {
    list-style: none;
    padding-left: 25px;
    color: $text-secondary;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    a.link-primary {
      color: red;
    }

    &.txt-bold {
      @include text-font($helvetica, $dark-1, $font-medium-bigger, 700, 20px);
    }

    &::before {
      content: '\2022';
      /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $color-grey-dusty;
      /* Change the color */
      font-weight: bold;
      /* If you want it to be bold */
      display: inline-block;
      /* Needed to add space between the bullet and the text */
      width: 20px;
      /* Also needed for space (tweak if needed) */
      margin-left: -20px;
      /* Also needed for space (tweak if needed) */
    }
  }
}

.deposit-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  min-height: 232px;
  padding-top: 16px;

  .address-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // gap: 16px;
    width: 100%;
    border-radius: 7px;
    background-color: #f8f8f8;
    padding: 16px;

    label {
      color: var(--text-secondary);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding-top: 16px;
      margin-bottom: 8px;

      @include mobile {
        font-size: 14px;
      }
    }

    .form-important {
      display: inline;
      color: #a87f2f;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 0px;
      padding: 16px;
      height: auto;
      background-color: #fff9ed;
      border: 1px solid #a87f2f;
      border-radius: 8px;
    }

    .address {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 10.5px 16px;
      gap: 10px;
      width: 100%;
      height: 40px;
      background: $color-white;
      border: 1px solid $grey-1;
      border-radius: 12px;
      outline: none;
      box-shadow: inset 0 0px 0px $color-black;
      margin-bottom: 16px;
    }

    .form-address {
      display: flex;
    }
  }

  .btn-box {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 17px;

    @media (max-width: 1440px) {
      flex-direction: column-reverse;
      gap: 8px;
    }

    @include tablet {
      flex-direction: row;
    }

    .btn {
      flex: 1;
      height: 41px;

      // &:hover {
      //   background-color: $color-blue-custom;
      //   border-color: $color-blue-custom;
      // }

      &.box_left {
        border: 1px solid $bg-main1 !important;
      }

      &.box_left,
      &.box_right {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 9px;
        border: 1px solid $bg-main6;
        border-radius: 60px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        outline: none;

        @include mobile {
          padding: 6px 24px;
        }
      }

      &.box_left {
        color: $text-main;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        background: $color-white;
      }

      &.box_right {
        color: $color-white;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        background: $bg-main6;
      }

      .icon_btn {
        width: 24px;
        height: 24px;
      }
    }
  }
}

// .go-to-trade {
//   width: 135px;
//   height: 19px;
//   font-family: Roboto;
//   font-size: 25px;
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: normal;
//   letter-spacing: normal;
//   color: #12575f;
// }
.change {
  padding-top: 30px;
  display: flex;
}

.chrome_only[tabindex]:focus {
  background-color: $color-blue-custom;
  border-color: $color-blue-custom;
}
</style>
