<template>
  <div id="order_form" :class="{ noBottom: !isLogin }" class="page_container clearfix">
    <div class="order-type">
      <div class="dropdown style-focus" tabindex="12">
        <button class="btn form-control btn-order-form" type="button" data-toggle="dropdown">
          <span class="left order-type-selected" v-if="tab == 'limit'">{{
            $t('order.order_form.limit')
          }}</span>
          <span class="left order-type-selected" v-if="tab == 'market'">{{
            $t('orders.open_order.market')
          }}</span>
          <span class="left order-type-selected" v-if="tab == 'stop_limit'">{{
            $t('order.order_form.stop_limit')
          }}</span>
          <span class="left order-type-selected" v-if="tab == 'stop_market'">{{
            $t('order.order_form.stop_market')
          }}</span>
          <span class="icon icon-arrow1"></span>
        </button>
        <ul class="dropdown-menu">
          <div
            v-on:click="tab = 'limit'"
            class="tab-element"
            v-bind:class="{ active: tab === 'limit' }"
          >
            <span class="text-size">{{ $t('order.order_form.limit') }}</span>
          </div>
          <div
            v-on:click="tab = 'market'"
            class="tab-element"
            v-bind:class="{ active: tab === 'market' }"
          >
            <span class="text-size">{{ $t('orders.open_order.market') }}</span>
          </div>
          <!-- <div
            v-on:click="tab = 'stop_limit'"
            class="tab-element"
            v-bind:class="{ active: tab === 'stop_limit' }"
          >
            <span class="text-size">{{ $t('order.order_form.stop_limit') }}</span>
            <span class="question-sign" @click="showTooltipLimit()" v-click-outside="hideTooltipLimit"></span>
            <div v-if="isShowTooltipLimit" class="tooltip-order">
              <p class="text-tooltip">
                {{ $t('order.order_form.stop_limit_tooltip') }}
              </p>
              <a
                :href="$siteSupportArticle($i18n.locale, 'how-to-use-stop-limit-function')"
                target="_blank"
              >
                {{ $t('order.order_form.view_more') }}</a
              >
            </div>
          </div> -->
          <!-- <div
            v-on:click="tab = 'stop_market'"
            class="tab-element"
            v-bind:class="{ active: tab === 'stop_market' }"
          >
            <span class="text-size">{{ $t('order.order_form.stop_market') }}</span>
            <span class="question-sign" @click="showTooltipMarket()" v-click-outside="hideTooltipMarket"></span>
            <div v-if="isShowTooltipMarket" class="tooltip-order">
              <p class="text-tooltip">
                {{ $t('order.order_form.stop_market_tooltip') }}
              </p>
              <a
                :href="$siteSupportArticle($i18n.locale, 'how-to-use-stop-limit-function')"
                target="_blank"
                >{{ $t('order.order_form.view_more') }}</a
              >
            </div>
          </div> -->
        </ul>
      </div>
    </div>

    <div class="tab_content">
      <order-form-tabs
        :tab="tab"
        @refreshOderGroup="$emit('refreshOderGroup')"
        :latestPrices="latestPrices"
      ></order-form-tabs>
    </div>
  </div>
</template>
<script>
import OrderFormTabs from './OrderFormTabs.vue';

export default {
  props: ['latestPrices'],
  components: {
    OrderFormTabs,
  },

  data() {
    return {
      isLogin: window.isAuthenticated,
      tab: 'limit',
      isShowTooltipLimit: false,
      show_select: false,
      isShowTooltipMarket: false,
    };
  },
  methods: {
    getOrderTypeName() {
      const result = window._.find(this.getOrderTypes(), (item) => item.name === this.orderType);
      return result ? result.name : 'Limit';
    },
    getOrderTypes() {
      return [
        { name: 'Limit' },
        { name: 'Market' },
        { name: 'Stop Limit' },
        { name: 'Stop Market' },
      ];
    },
    showTooltipLimit() {
      this.hideTooltipMarket();
      this.isShowTooltipLimit = !this.isShowTooltipLimit;
    },
    hideTooltipLimit() {
      this.isShowTooltipLimit = false;
    },

    showTooltipMarket() {
      this.hideTooltipLimit();
      this.isShowTooltipMarket = !this.isShowTooltipMarket;
    },
    hideTooltipMarket() {
      this.isShowTooltipMarket = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#order_form {
  display: block;
  width: 100%;
  padding: 20px 14px;
  .dropdown {
    &.open {
      .icon-arrow1 {
        rotate: 180deg;
      }
    }
    &:focus-visible {
      outline: 1px solid $bg-main1;
    }
  }
  .order-type {
    position: relative;
    margin-bottom: 16px;
    .btn-order-form {
      border-radius: 5px;
      border: solid 1px $bg-main2;
      color: $text-main;
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      padding: 8px 16px;
      background-color: $color-white;
      text-align: left;
      width: 100%;
      height: auto;
      overflow: hidden;
      &:focus,
      &:active {
        outline: none !important;
      }
      .icon {
        display: inline;
        float: right;
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 12px;
      }
    }
    .dropdown-menu {
      width: 100%;
      border-radius: 10px;
      border: none;
      box-shadow: 0px 4px 24px 0px rgba(black, 0.16);
      background-color: $color-white;
      padding: 10px 0px;
      .tab-element {
        cursor: pointer;
        display: block;
        width: 100%;
        line-height: 22px;
        padding: 10px 20px;
        font-size: 14px;
        font-family: $helvetica;
        color: $text-main;
        font-weight: 300;
        &:hover {
          background: $grey-3;
        }

        &.active {
          background: $bg-main3;
        }
      }
    }
  }
  @include tablet {
    &.noBottom {
      border-bottom: unset !important;
    }
  }
  @include mobile {
    &.noBottom {
      border-bottom: unset !important;
    }
  }
}
.style-focus {
  border-radius: 10px;
  outline-width: 1px;
}
</style>
