<template>
  <div class="term-page">
   <TermsOfService />
  </div>
</template>
<script>
import TermsOfService from '@/components/webview-pages/TermsOfService.vue';

export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_URL,
      urlPolicy: `${window.location.origin}/privacy-policy`,
    };
  },
  components: {
    TermsOfService,
  },
  mounted() {
    document.title = this.$t('menu.terms') + ` – ${this.tabTitle}`;
  },
};
</script>
<style lang="scss" scoped>
.term-page {
  max-width: 1280px;
  margin: 0 auto;
  background-color: #f2f2f2;
  padding: 24px 0;
  font-family: $helvetica;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: $text-main;
    margin-bottom: 4px;
  }

  .time-revised {
    margin-bottom: 24px;

    p {
      font-size: 16px;
      // font-weight: 300;
      line-height: 24px;
      color: $text-secondary;
      line-height: 24px;
    }
  }

  .content-wrap {
    .content-general {
      margin-bottom: 24px;

      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        color: $text-secondary;
        margin: unset;

        :deep a {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          color: rgba(2, 0, 13, 0.6);
        }
      }
    }

    .title-header {
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      color: $text-main;
      margin-bottom: 8px;

      p {
        margin-bottom: 0;
      }
    }

    .term-content {
      margin-bottom: 24px;

      .content-ac {
        div,
        p {
          font-size: 16px;
          line-height: 24px;
          color: $text-secondary;
          margin-bottom: 24px;
        }

        div {
          margin-bottom: 0px;
        }

        .term__sub-content {
          margin-bottom: 24px;

          p {
            margin: unset;
          }

          & > p:first-child {
            color: $text-main;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media (min-width: #{$desktop-width}) {
  .term-page {
    max-width: 1280px + 16px * 2;
    padding: 24px 16px;
  }
}

@include tablet-d() {
  .term-page {
    padding: 24px 16px;

    .content-wrap {
      .content-general p {
        font-size: 14px;
      }

      .term-content {
        .title-header {
          font-size: 16px;
        }

        .content-ac div,
        .content-ac p {
          font-size: 14px;
        }
      }
    }
  }
}

@include mobile() {
  .term-page {
    padding: 21px 24px;

    .title {
      font-size: 18px;
      margin-bottom: 5px;
    }

    .time-revised {
      p {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 24px;
        color: $text-secondary;
      }
    }

    .content-wrap {
      // padding: 14px 15px;
      .content-general {
        margin-bottom: 5px;

        p {
          font-size: 14px;
        }
      }

      .title-header {
        font-size: 18px;
        margin-bottom: 10px;

        p {
          margin-bottom: 0;
        }
      }

      .term-content {
        .content-ac {
          div,
          p {
            margin-bottom: 0px;
            font-size: 14px;
          }

          div {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#footer,
#header {
  &.disable-app {
    display: none;
  }
}

.page-container {
  &.isContentApp {
    min-width: auto;
    min-height: auto;
    width: 100%;
    margin: 0px;
    max-width: 100%;

    .term-page {
      padding: 0px;
    }
  }
}
</style>
